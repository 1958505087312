import AppConfig from 'AppConfig';
import http from 'api/http';
import { handleResponse } from 'api/helpers';

export default async function addAttachment({
  filesPrefix,
  filename,
  file,
  signal,
}: {
  filesPrefix: string;
  filename: string;
  file: File;
  signal: AbortSignal;
}): Promise<unknown> {
  const response = await http.post(`${AppConfig.highbondDriveUrl}/objects?id=${filesPrefix}/${filename}`, null, {
    signal,
  });

  return await handleResponse(response, (json) => {
    const uploadUrl = json.data.attributes.upload_url;

    return fetch(uploadUrl, {
      method: 'PUT',
      body: file,
      signal,
    });
  });
}
