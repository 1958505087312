// Do not edit this file, it is automatically generated
const locales = {
  "fr": {
    "translation": {
      "app_name": {
        "asset_inventory": "Stock d'actifs",
        "risk_register": "Registre des risques",
        "default": "Stock d'objets"
      },
      "activities_page": {
        "activities": "Activités",
        "generate_activity_log": "Générer un journal d'activités",
        "bubbly_message": "Générez un journal contenant l'activité de votre organisation dans le stock d'actifs et dans le registre des ris au cours du mois précédent.\nNous vous enverrons un e-mail contenant un lien qui vous permettra de télécharger le journal d'activités.\n",
        "bubbly_warning": "Le lien de téléchargement expirera 30 minutes après la réception de l'e-mail.",
        "success_toast_message": "Votre exportation a commencé. Nous vous enverrons un e-mail avec un lien vers votre fichier journal d'activités une fois qu'il sera prêt.\nVeuillez noter que le processus d'exportation peut prendre quelques instants si votre fichier contient un grand nombre d'activités.\n",
        "export_activity_log": "Exporter le journal d'activité"
      },
      "activity_log": "Activité",
      "object_types": {
        "asset": "Actif",
        "record": "Enregistrement"
      },
      "breadcrumbs": "Fils d'Ariane",
      "links": {
        "support_url": "https://community.wegalvanize.com/s/contactsupport?language=fr",
        "empty_assessments_help_url": "https://help.highbond.com/helpdocs/highbond/fr/Default.htm#cshid=pm-asset-workflows"
      },
      "no_results": {
        "heading": "Aucun résultat trouvé",
        "body": "Veuillez affiner votre recherche ou choisir un autre filtre."
      },
      "empty_results": {
        "heading": "Aucun élément actuellement",
        "body": "Ajoutez {{- recordTypeName}} pour créer un nouvel enregistrement pour ce(t)/cette {{- parentName}}."
      },
      "error_notification": {
        "heading": "Un problème est survenu",
        "body": "Un incident est survenu. Veuillez rafraîchir la page pour essayer à nouveau. Si le problème persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contactez notre équipe d'assistance</a>."
      },
      "no_access_notification": {
        "heading": "Aucun accès",
        "body": "Vous n'avez pas accès à ces données. Essayez de contacter un admin HighBond de votre organisation pour voir si vous devriez avoir accès."
      },
      "asset_types_page": {
        "page_title": {
          "asset_inventory": "Stock d'actifs - HighBond",
          "risk_register": "Registre des risques - HighBond",
          "default": "Stock d'objets - HighBond"
        },
        "heading": {
          "asset_inventory": "Stock d'actifs",
          "risk_register": "Registre des risques",
          "default": "Stock d'objets"
        },
        "asset_type": {
          "asset_inventory": "Type d'actif",
          "default": "Type"
        },
        "description": "Description"
      },
      "assets_index_page": {
        "page_title": {
          "asset_inventory": "{{- assetTypeName}} - Stock d'actifs",
          "risk_register": "{{- assetTypeName}} - Registre des risques",
          "default": "{{- assetTypeName}} - Stock d'objets"
        },
        "not_configured": {
          "heading": "{{- appTitle}} non configuré",
          "message": "{{- appTitle}} n'est pas encore configuré dans votre organisation. Veuillez contacter votre administrateur système."
        }
      },
      "details_page": {
        "heading": "Détails sur {{- assetTypeName}}",
        "page_title": {
          "asset_inventory": "{{- assetName}} - Stock d'actifs",
          "risk_register": "{{- assetName}} - Registre des risques",
          "default": "{{- assetName}} - Stock d'objets"
        },
        "error": "Un incident est survenu. Veuillez rafraîchir la page pour essayer à nouveau. Si le problème persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contactez notre équipe d'assistance</a>.",
        "workflow_status": {
          "status_transition_errors": "Un problème est survenu pendant l'enregistrement de {{- name}} :",
          "status_transition_errors_plural": "Des problèmes sont survenus pendant l'enregistrement de {{- name}} :",
          "updated_by": "par {{userName}}"
        },
        "add_record": "Ajouter {{- recordTypeName}}",
        "overview": "Aperçu",
        "details": "Détails",
        "loading": "Chargement...",
        "number_of_attributes": "{{attributesCount}} attributs",
        "number_of_required_attributes": "{{attributesCount}} attributs nécessaires",
        "questionnaires": {
          "no_results": {
            "heading": "Vous recherchez des évaluations ?",
            "body": "Vous pouvez lancer une évaluation au moyen des contrôles de flux de travail en haut de l'écran. <a href='{{supportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">En savoir plus</a>"
          },
          "side_panel": {
            "header": "Envoyer le questionnaire",
            "select_questionnaire_label": "Questionnaire",
            "select_questionnaire_placeholder": "Sélectionner un questionnaire...",
            "select_email_label": "Destinataire",
            "contact_instructions": "Saisir un e-mail spécifique ou sélectionner un utilisateur existant pour recevoir le questionnaire",
            "invalid_email": "Saisissez une adresse e-mail valide.",
            "asset_type_validation": {
              "asset_inventory": "Cet actif n'a pas été défini pour les questionnaires d'évaluation. Contactez un administrateur pour lier les questionnaires d'évaluation à ce type d'actif.",
              "default": "Cet objet n'a pas été défini pour les questionnaires d'évaluation. Contactez un administrateur pour lier les questionnaires d'évaluation à ce type."
            },
            "contact_custom_text": "Utiliser une adresse e-mail spécifique",
            "contact_custom_instructions": "Rechercher un utilisateur ou saisir une adresse e-mail spécifique",
            "search_placeholder": "Rechercher..."
          }
        },
        "section_header": {
          "additional_information": "Informations supplémentaires",
          "number_of_errors": {
            "singular": "{{numberOfErrors}} erreur",
            "plural": "{{numberOfErrors}} erreurs"
          },
          "required_attributes_missing": "{{numberOfPresentAttributes}} sur {{numberOfRequiredAttributes}} obligatoire(s)"
        }
      },
      "actions": {
        "actions": "Actions",
        "send": "Envoyer",
        "apply": "Appliquer",
        "close": "Fermer",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "decline": "Refuser",
        "approve": "Approuver",
        "save_changes": "Enregistrer les modifications",
        "discard_changes": "Abandonner les modifications",
        "add_asset": "Ajouter {{- assetTypeName}}",
        "delete": "Supprimer",
        "delete_asset": {
          "label": "Supprimer",
          "heading": "Supprimer {{- assetName}} ?",
          "confirmation": {
            "asset_inventory": "Cette opération supprimera définitivement {{- assetName}} et tout autre travail associé, dont le profil de l'actif, les évaluations et les enregistrements liés. Vous ne pourrez plus accéder à aucun de ces éléments ni les restaurer.",
            "default": "Cette opération supprimera définitivement {{- assetName}} et tout autre travail associé, dont son profil, les évaluations et les enregistrements liés. Vous ne pourrez plus accéder à aucun de ces éléments ni les restaurer."
          }
        },
        "delete_record": {
          "label": "Supprimer",
          "heading": "Supprimer {{- recordName}} ?",
          "confirmation": "Voulez-vous vraiment supprimer {{- recordName}} ?  Cette opération supprimera tout travail associé, dont ses enregistrements liés. Vous ne pourrez plus accéder à aucun de ces éléments ni les restaurer."
        },
        "download_report": "Télécharger le rapport",
        "download": "Télécharger",
        "sort": {
          "ascending": "Tri croissant",
          "descending": "Tri décroissant"
        },
        "data_header_menu_trigger": {
          "hide_column": "Masquer la colonne",
          "direction": {
            "ascending": "croissant",
            "descending": "décroissant"
          },
          "with_direction": "Colonne {{- columnName}}, tri {{- sortDirection}}, autres actions.",
          "without_direction": "Colonne {{- columnName}}, autres actions.",
          "sort_column_ascending": "Trier {{- columnName}} par ordre croissant.",
          "sort_column_descending": "Trier {{- columnName}} par ordre décroissant."
        }
      },
      "data_types": {
        "boolean": {
          "true": "Vrai",
          "false": "Faux"
        },
        "datetime": {
          "date": "Date",
          "time": "Heure"
        },
        "ad_hoc_relationship": {
          "cancel": "Annuler",
          "connection_type": "Type de connexion",
          "link": "Lier",
          "unlink": "Annuler le lien",
          "search_display_name": "Filtrer {{displayName}}",
          "add": "Ajouter {{label}}",
          "target_object_display_title": "ID {{- customerObjectId}} - {{- customerObjectName}}",
          "search_issue": "Rechercher...",
          "no_results": "Aucun résultat trouvé"
        },
        "attachment": {
          "add": "Ajouter {{attributeTypeName}}",
          "remove": "Supprimer pièce jointe {{fileName}} ?",
          "grid": {
            "id": "Nom",
            "last_modified": "Chargé(e)",
            "modified_by": "Chargé(e) par",
            "actions": "Actions"
          },
          "loading": "Chargement..."
        }
      },
      "global_notification": {
        "customer_object_created": {
          "success": "{{- customerObjectTypeName}} \"{{- customerObjectName}}\" a été correctement ajouté(e)"
        },
        "asset_updated": {
          "success": "Vous avez bien mis à jour un {{- assetTypeName}} !",
          "conflict": {
            "title": "Enregistrer le conflit",
            "description": "Vos modifications sont en conflit avec celles effectuées par un autre utilisateur.\nPour enregistrer vos modifications, copiez vos données, rafraîchissez la page et soumettez à nouveau vos modifications.\n"
          }
        },
        "asset_deleted": {
          "success": "{{- assetTypeName}} \"{{- assetName}}\" a été correctement supprimé(e).",
          "error": "La suppression de \"{{- assetName}}\" a échoué."
        },
        "go_to": "Passer à \"{{- assetName}}\"",
        "record_updated": {
          "success": "Vous avez correctement mis à jour un enregistrement !"
        },
        "questionnaire_sent": {
          "success": "Le questionnaire a été envoyé."
        }
      },
      "side_navigation": {
        "objects_heading": {
          "asset_inventory": "Actifs",
          "default": "Objets"
        },
        "records_heading": "Enregistrements"
      },
      "error_page": {
        "page_title": {
          "asset_inventory": "{{heading}} - stock d'actifs",
          "risk_register": "{{heading}} - Registre des risques",
          "default": "{{heading}} - Stock d'objets"
        },
        "page_not_found": {
          "heading": "Page introuvable",
          "body": "Nous n'avons pas trouvé la page que vous recherchez. Vous pouvez revenir à notre <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">page d'accueil du système</a>, ou <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">contactez notre équipe d'assistance</a> pour obtenir plus d'aide."
        },
        "access_not_allowed": {
          "heading": "L'accès n'est pas autorisé",
          "body": "D'après nos enregistrements, vous n'avez pas l'autorisation nécessaire pour accéder à cette page. Essayez de contacter un admin HighBond de votre organisation pour voir si vous devriez avoir accès. Vous pouvez également retourner à la <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">page d'accueil du système</a>, ou <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">contactez notre équipe d'assistance</a> pour obtenir de l'aide supplémentaire."
        },
        "footer": "Pour obtenir de l'aide supplémentaire,  <a href='{{visitOurSupportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">visitez notre page d'assistance</a>, pour poster un ticket ou discuter en ligne avec un représentant."
      },
      "add_asset_modal": {
        "unsaved_changes_warning": "Vous avez effectué des modifications qui seront perdues si vous fermez ce formulaire.",
        "add_new_item": "Ajouter",
        "name_is_required": "Le nom est obligatoire.",
        "item_type": "Type d'élément",
        "name": "Nom"
      },
      "dirty_form": {
        "title": "Ce/Cette {{- objectTypeName}} comportes des modifications non enregistrées.",
        "missing_name_error": "Ce(cette) {{- objectTypeName}} ne peut pas être enregistré(e) tant que \"nom\" est vide.",
        "description": "Il semblerait que vous soyez en train d'effectuer des mises à jour. Si vous poursuivez, vous perdrez toutes les modifications non enregistrées.",
        "save_and_continue": "Enregistrer et poursuivre",
        "discard_and_continue": "Poursuivre sans enregistrer",
        "cancel": "Annuler"
      },
      "impact_reports_modal": {
        "download_report": {
          "title": "Télécharger le rapport",
          "can_be_downloaded": "Vous pouvez télécharger les rapports aux formats suivants.",
          "ready_to_download": "Votre rapport est prêt à être téléchargé."
        },
        "just_a_moment": {
          "title": "Patientez un instant !",
          "description": "Nous préparons actuellement votre rapport. Cette opération peut prendre plusieurs minutes en fonction de la taille du rapport. Le bouton de téléchargement s'affichera lorsque le rapport sera prêt."
        },
        "format_selection": {
          "label": "Formats des rapports",
          "instructions": "Sélectionner un format de rapport"
        },
        "docx_format": "Microsoft Word",
        "xlsx_format": "Microsoft Excel",
        "pptx_format": "Microsoft PowerPoint",
        "pdf_format": "PDF",
        "no_reports": {
          "title": "Aucun rapport trouvé.",
          "description": "Actuellement, aucun rapport n'est disponible au téléchargement."
        }
      },
      "errors": {
        "handlers": {
          "required_fields_missing": "{{attributeNameComponent}} est obligatoire.",
          "required_relationships_missing": {
            "asset_inventory": "{{attributeNameComponent}} doit être lié(e) au moins à un actif ou un enregistrement d'actif.",
            "default": "{{attributeNameComponent}} doit être lié(e) au moins à un objet."
          },
          "status_not_found": "Le statut de destination ({{statusId}}) n'est pas dans le flux de travail du statut actuel.",
          "questionnaire_not_found": "Le questionnaire n'a pas été trouvé dans les gestionnaires.",
          "failed_to_update_status": "Impossible de mettre à jour le statut du flux de travail. Veuillez rafraîchir la page pour essayer à nouveau. Si le problème persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contactez notre équipe d'assistance</a>.",
          "failed_to_send_questionnaire": "Impossible d'envoyer un questionnaire d'évaluation. Veuillez rafraîchir la page pour essayer à nouveau. Si le problème persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contactez notre équipe d'assistance</a>.",
          "failed_to_publish_protobuf": "Impossible d'exécuter l'action « notify » ou « runRobot ». Veuillez rafraîchir la page pour essayer à nouveau. Si le problème persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">contactez notre équipe d'assistance</a>."
        },
        "form": {
          "missing_field_value": "Le champ ne peut pas être vide.",
          "missing_relationship_value": {
            "asset_inventory": "Le champ doit être lié au moins à un actif ou un enregistrement d'actif.",
            "default": "Le champ doit être lié à au moins un objet."
          },
          "invalid_field_value": "Le champ a une valeur non valide."
        },
        "configuration": {
          "section_missing_attribute_error": "Certains attributs qui ne vous sont pas visibles doivent être remplis avant que vous puissiez continuer. Veuillez contacter votre administrateur pour examiner la configuration de ce(cette) {{- customerObjectTypeName}}.\n"
        }
      },
      "metadata": {
        "record_id": "ID enregistrement",
        "position": "Position",
        "priority": "Priorité",
        "status": "Statut",
        "publish_date": "Date de publication",
        "publisher": "Publieur",
        "assignee": "Assigné",
        "group": "Groupe",
        "updated_at": "Mis à jour à",
        "closed_at": "Fermé à",
        "object_type": "Type d'objet",
        "object_id": "ID objectif",
        "object_container_type": "Type de conteneur d'objet",
        "object_container_id": "ID de conteneur d'objet"
      },
      "protected": {
        "source": "Source",
        "user_id": "ID utilisateur",
        "modified_by": "Modifié(e) par",
        "status_id": "ID du statut",
        "priority_id": "ID de priorité",
        "group_id": "ID de groupe",
        "external_source": "Source externe",
        "external_type": "Type externe",
        "external_id": "ID externe",
        "questionnaire_context": "Contexte du questionnaire"
      }
    }
  }
};
export default locales;