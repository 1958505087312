import _ from 'lodash';
import {
  AssetAttributes,
  AttributeType,
  AttributeTypeDataType,
  AttributeValue,
  HttpConflictErrorResponse,
  HttpErrorResponse,
  HttpStatusCode,
  RelationshipValue,
  ServerError,
  UserLookupValue,
  Page,
  AssetType,
  AssetRecordType,
  SectionItemType,
} from 'types';
import AppConfig from 'AppConfig';

interface ServerUserLookupValue {
  user_ids: string[];
  group_ids: string[];
}

type ServerValue = ServerUserLookupValue | string[] | Record<string, string> | null;

interface ServerValueFromat {
  field_name: string;
  value: ServerValue;
}

export function isBlank(value: unknown): boolean {
  return value === undefined || value === null || value === '';
}

export function convertAttributesToServerFormat(
  attributes: AssetAttributes,
  attributeTypes: AttributeType[],
): ServerValueFromat[] {
  const attributeTypesByFieldName = _.keyBy(attributeTypes, (type) => type.fieldName);

  function removeMetadata(fieldName: string) {
    return !fieldName.startsWith('metadata.');
  }

  function convertToSnakeCase(object: Record<string, any>): Record<string, any> {
    return _.mapKeys(object, (_value, key) => _.snakeCase(key));
  }

  function getServerValue(savedValue: any, attributeType: AttributeType): ServerValue {
    if (isBlank(savedValue)) {
      return null;
    }

    if (attributeType.dataType === AttributeTypeDataType.userLookup) {
      return convertToSnakeCase(savedValue) as ServerUserLookupValue;
    }

    if (attributeType.dataType === AttributeTypeDataType.relationship && attributeType?.typeOptions?.hasOne === true) {
      return convertToSnakeCase({ id: savedValue.id, objectTypeId: savedValue.objectTypeId });
    }

    if (attributeType.dataType === AttributeTypeDataType.text) {
      return [savedValue.trim()];
    }

    return [`${savedValue}`];
  }

  function toServerFormat(fieldName: string) {
    const value = attributes[fieldName];
    const type = attributeTypesByFieldName[fieldName];

    return {
      field_name: fieldName,
      value: getServerValue(value, type),
    };
  }

  return Object.keys(attributes).filter(removeMetadata).map(toServerFormat);
}

async function getJSONErrors(response: Response): Promise<ServerError[]> {
  try {
    const result = await response.json();
    return result?.errors ?? [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function handleResponse(response: Response, onSuccess: (json: any | null) => any): Promise<any> {
  if (response.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
    throw new HttpErrorResponse(response);
  }

  if (response.status === HttpStatusCode.CONFLICT) {
    throw new HttpConflictErrorResponse(response);
  }

  if (!response.ok) {
    const errors = await getJSONErrors(response);
    throw new HttpErrorResponse(response, errors);
  }

  if (response.status === HttpStatusCode.NO_CONTENT) {
    return onSuccess(null);
  }

  try {
    return onSuccess(await response.json());
  } catch (e) {
    return onSuccess(null);
  }
}

function parseStringArrayValue(values: string[], attributeType: AttributeType): AttributeValue {
  if (values.length === 0) {
    return null;
  }

  const firstValue = values[0];

  switch (attributeType.dataType) {
    case AttributeTypeDataType.boolean: {
      return firstValue !== undefined ? firstValue === 'true' : null;
    }
    case AttributeTypeDataType.number: {
      return Number(firstValue);
    }
    case AttributeTypeDataType.dateTime: {
      return new Date(firstValue).toISOString();
    }
    default: {
      return firstValue;
    }
  }
}

function parseObjectValue(value: Record<string, any>, attributeType: AttributeType): AttributeValue {
  if ('userIds' in value && 'groupIds' in value) {
    return value as UserLookupValue;
  }

  if ('id' in value && 'objectTypeId' in value) {
    return value as RelationshipValue;
  }

  return null;
}

function parseValue(values: any, attributeType: AttributeType): AttributeValue | undefined {
  if (attributeType.dataType === AttributeTypeDataType.relationship && attributeType?.typeOptions?.hasOne === false) {
    return undefined;
  }

  if (
    attributeType.dataType === AttributeTypeDataType.adHocRelationship ||
    attributeType.dataType === AttributeTypeDataType.adHocRelationshipLabel
  ) {
    return undefined;
  }

  if (attributeType.dataType === AttributeTypeDataType.attachment) {
    return values;
  }

  if (values instanceof Array) {
    return parseStringArrayValue(values, attributeType);
  }

  if (values instanceof Object) {
    return parseObjectValue(values, attributeType);
  }

  return null;
}

export function postProcessAttributes(
  attributes: Record<string, any>,
  attributeTypes: AttributeType[],
): Record<string, AttributeValue> {
  return attributeTypes.reduce((result: Record<string, AttributeValue>, attributeType) => {
    if (attributeType.fieldName === 'metadata.workflow_status') {
      return result;
    }

    const values = attributes[attributeType.fieldName]?.value ?? [];
    const value = parseValue(values, attributeType);
    if (value !== undefined) {
      result[attributeType.fieldName] = value;
    }
    return result;
  }, {});
}

interface PreformattedLinks {
  first: string;
  prev: string;
  next: string;
  last: string;
}

export function formatPaginationLinks({ first, prev, next, last }: PreformattedLinks): Page<string> {
  const prefixAPI = (url: string): string => `${AppConfig.assetsInventoryApiUrl}${url}`;

  return {
    first: first && prefixAPI(first),
    previous: prev && prefixAPI(prev),
    next: next && prefixAPI(next),
    last: last && prefixAPI(last),
  };
}

export function addFieldNameToSectionAttributeTypes(objectType: AssetType | AssetRecordType): void {
  const fieldNameById = objectType.attributeTypes.reduce((collection, attributeType) => {
    collection[attributeType.id] = attributeType.fieldName;
    return collection;
  }, {} as Record<string, string>);

  objectType.sections.forEach((section) => {
    section.items.forEach((item) => {
      if (item.type === SectionItemType.ATTRIBUTE_TYPE) {
        if (fieldNameById[item.id]) {
          item.fieldName = fieldNameById[item.id];
        }
      }
    });
  });
}
