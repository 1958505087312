export enum AttributeTypeDataType {
  boolean = 'boolean',
  paragraph = 'paragraph',
  dateTime = 'dateTime',
  number = 'number',
  text = 'text',
  rowId = 'rowId',
  select = 'select',
  userLookup = 'userLookup',
  assessment = 'assessment',
  relationship = 'relationship',
  adHocRelationship = 'adHocRelationship',
  adHocRelationshipLabel = 'adHocRelationshipLabel',
  attachment = 'attachment',
}

export interface AttributeType {
  id: string;
  dataType: AttributeTypeDataType;
  displayName: string;
  fieldName: string;
  isMetadata: boolean;
  isRequired: boolean;
  typeOptions: {
    default?: AttributeValue;
    [key: string]: any;
  };
}

export type AttributeValue =
  | boolean
  | number
  | string
  | Array<string>
  | UserLookupValue
  | RelationshipValue
  | AttachmentValue
  | null;

export interface UserLookupValue {
  userIds: string[];
  groupIds: string[];
}

export interface RelationshipValue {
  id: string;
  objectTypeId: string;
}

export interface AttachmentValue {
  filesPrefix: string;
  link: string;
}

export enum AttributeValidationError {
  NameIsRequired = 'NameIsRequired',
}

export type AssetAttributes = { [fieldName: string]: AttributeValue };
