// Do not edit this file, it is automatically generated
const locales = {
  "es": {
    "translation": {
      "app_name": {
        "asset_inventory": "Inventario de activos",
        "risk_register": "Registro de riesgos",
        "default": "Inventario de objetos"
      },
      "activities_page": {
        "activities": "Actividades",
        "generate_activity_log": "Generar log de actividad",
        "bubbly_message": "Genere un log que contenga la actividad de su organización en el Inventario de activos y el Registro de riesgos durante el último mes.\nLe enviaremos un correo electrónico con un enlace donde puede descargar el log de actividades.\n",
        "bubbly_warning": "El enlace de descarga caduca 30 minutos después de recibir el correo electrónico.",
        "success_toast_message": "La exportación ha comenzado. Le enviaremos un correo electrónico con un enlace al archivo de log de actividades una vez que esté listo.\nTenga en cuenta que si el archivo contiene una gran cantidad de actividades, el proceso de exportación puede llevar un tiempo.\n",
        "export_activity_log": "Exportar log de actividad"
      },
      "activity_log": "Actividad",
      "object_types": {
        "asset": "Activo",
        "record": "Registro"
      },
      "breadcrumbs": "Rutas de navegación",
      "links": {
        "support_url": "https://community.wegalvanize.com/s/contactsupport?language=es_mx",
        "empty_assessments_help_url": "https://help.highbond.com/helpdocs/highbond/es/Default.htm#cshid=pm-asset-workflows"
      },
      "no_results": {
        "heading": "No se han encontrado resultados",
        "body": "Por favor, refine su búsqueda o elija un filtro diferente."
      },
      "empty_results": {
        "heading": "¡Nada aquí todavía!",
        "body": "Agregue {{- recordTypeName}} para crear un nuevo registro para este/a {{- parentName}}."
      },
      "error_notification": {
        "heading": "Ha ocurrido un problema",
        "body": "Algo salió mal. Por favor, actualice la página para volver a intentarlo. Si el problema persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">comuníquese con nuestro equipo de soporte</a>."
      },
      "no_access_notification": {
        "heading": "Sin acceso",
        "body": "No tiene acceso a estos datos. Intente comunicarse con un administrador de HighBond en su organización para saber si debería tener acceso."
      },
      "asset_types_page": {
        "page_title": {
          "asset_inventory": "Inventario de activos - HighBond",
          "risk_register": "Registro de riesgos - HighBond",
          "default": "Inventario de objetos - HighBond"
        },
        "heading": {
          "asset_inventory": "Inventario de activos",
          "risk_register": "Registro de riesgos",
          "default": "Inventario de objetos"
        },
        "asset_type": {
          "asset_inventory": "Tipo de activo",
          "default": "Tipo"
        },
        "description": "Descripción"
      },
      "assets_index_page": {
        "page_title": {
          "asset_inventory": "{{- assetTypeName}} - Inventario de activos",
          "risk_register": "{{- assetTypeName}} - Registro de riesgos",
          "default": "{{- assetTypeName}} - Inventario de objetos"
        },
        "not_configured": {
          "heading": "{{- appTitle}} no está configurada",
          "message": "{{- appTitle}} aún no se configuró en su organización. Comuníquese con el administrador de su sistema."
        }
      },
      "details_page": {
        "heading": "Detalles de {{- assetTypeName}}",
        "page_title": {
          "asset_inventory": "{{- assetName}} - Inventario de activos",
          "risk_register": "{{- assetName}} - Registro de riesgos",
          "default": "{{- assetName}} - Inventario de objetos"
        },
        "error": "Algo salió mal. Por favor, actualice la página para volver a intentarlo. Si el problema persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">comuníquese con nuestro equipo de soporte</a>.",
        "workflow_status": {
          "status_transition_errors": "Ocurrió un problema al guardar {{- name}}:",
          "status_transition_errors_plural": "Ocurrieron algunos problemas al guardar {{- name}}:",
          "updated_by": "por {{userName}}"
        },
        "add_record": "Agregue {{- recordTypeName}}",
        "overview": "Panorama",
        "details": "Detalles",
        "loading": "Cargando...",
        "number_of_attributes": "{{attributesCount}} atributos",
        "number_of_required_attributes": "{{attributesCount}} atributos requeridos",
        "questionnaires": {
          "no_results": {
            "heading": "¿Busca evaluaciones?",
            "body": "Puede iniciar una evaluación utilizando los controles de flujo de trabajo en la parte superior de la pantalla. <a href='{{supportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">Obtener más información</a>"
          },
          "side_panel": {
            "header": "Enviar cuestionario",
            "select_questionnaire_label": "Cuestionario",
            "select_questionnaire_placeholder": "Seleccionar un cuestionario...",
            "select_email_label": "Destinatario",
            "contact_instructions": "Ingrese un correo electrónico personalizado o seleccione un usuario existente para que reciba el cuestionario",
            "invalid_email": "Por favor, introduzca una dirección de correo electrónico válida.",
            "asset_type_validation": {
              "asset_inventory": "Este activo no se ha configurado para cuestionarios de evaluación. Comuníquese con un administrador para enlazar los cuestionarios de evaluación a este tipo de activo.",
              "default": "Este objeto no se ha configurado para cuestionarios de evaluación. Comuníquese con un administrador para enlazar los cuestionarios de evaluación a este tipo."
            },
            "contact_custom_text": "Utilice como dirección de correo electrónico personalizada",
            "contact_custom_instructions": "Busque un usuario o ingrese una dirección de correo electrónico personalizada",
            "search_placeholder": "Buscar..."
          }
        },
        "section_header": {
          "additional_information": "Información adicional",
          "number_of_errors": {
            "singular": "{{numberOfErrors}} error",
            "plural": "{{numberOfErrors}} errores"
          },
          "required_attributes_missing": "{{numberOfPresentAttributes}} de {{numberOfRequiredAttributes}} requeridos"
        }
      },
      "actions": {
        "actions": "Acciones",
        "send": "Enviar",
        "apply": "Aplicar",
        "close": "Cerrar",
        "save": "Guardar",
        "cancel": "Cancelar",
        "decline": "Declinar",
        "approve": "Aprobar",
        "save_changes": "Guardar cambios",
        "discard_changes": "Descartar los cambios",
        "add_asset": "Agregar {{- assetTypeName}}",
        "delete": "Eliminar",
        "delete_asset": {
          "label": "Eliminar",
          "heading": "¿Eliminar {{- assetName}}?",
          "confirmation": {
            "asset_inventory": "Esto eliminará permanentemente {{- assetName}} y cualquier trabajo asociado, incluido el perfil de activos, evaluaciones y registros relacionados. No podrá acceder a ninguno de estos elementos ni restaurarlos.",
            "default": "Esto eliminará permanentemente {{- assetName}} y cualquier trabajo asociado, incluido el perfil, las evaluaciones y los registros relacionados. No podrá acceder a ninguno de estos elementos ni restaurarlos."
          }
        },
        "delete_record": {
          "label": "Eliminar",
          "heading": "¿Eliminar {{- recordName}}?",
          "confirmation": "¿Está seguro de que quiere eliminar {{- recordName}}?  Esto eliminará cualquier trabajo asociado, incluidos sus registros relacionados. No podrá acceder a ninguno de estos elementos ni restaurarlos."
        },
        "download_report": "Descargar reporte",
        "download": "Descargar",
        "sort": {
          "ascending": "Orden ascendente",
          "descending": "Orden descendente"
        },
        "data_header_menu_trigger": {
          "hide_column": "Ocultar columna",
          "direction": {
            "ascending": "ascendente",
            "descending": "descendente"
          },
          "with_direction": "Columna {{- columnName}}, ordenada {{- sortDirection}}, más acciones.",
          "without_direction": "Columna {{- columnName}}, más acciones.",
          "sort_column_ascending": "Ordenar {{- columnName}} de forma ascendente.",
          "sort_column_descending": "Ordenar {{- columnName}} de forma descendente."
        }
      },
      "data_types": {
        "boolean": {
          "true": "Verdadero",
          "false": "Falso"
        },
        "datetime": {
          "date": "Fecha",
          "time": "Hora"
        },
        "ad_hoc_relationship": {
          "cancel": "Cancelar",
          "connection_type": "Tipo de conexión",
          "link": "Enlazar",
          "unlink": "Desenlazar",
          "search_display_name": "Filtrar {{displayName}}",
          "add": "Agregar {{label}}",
          "target_object_display_title": "ID {{- customerObjectId}} - {{- customerObjectName}}",
          "search_issue": "Buscar...",
          "no_results": "No se han encontrado resultados"
        },
        "attachment": {
          "add": "Agregar {{attributeTypeName}}",
          "remove": "¿Quitar el archivo adjunto {{fileName}}?",
          "grid": {
            "id": "Nombre",
            "last_modified": "Cargado/a",
            "modified_by": "Cargado/a por",
            "actions": "Acciones"
          },
          "loading": "Cargando..."
        }
      },
      "global_notification": {
        "customer_object_created": {
          "success": "{{- customerObjectTypeName}} \"{{- customerObjectName}}\" se ha agregado con éxito."
        },
        "asset_updated": {
          "success": "¡Ha actualizado correctamente un {{- assetTypeName}}!",
          "conflict": {
            "title": "Guardar conflicto",
            "description": "Sus cambios entran en conflicto con los realizados simultáneamente por otro usuario.\nPara guardar sus cambios, copie sus datos, actualice la página y vuelva a enviar sus cambios\n"
          }
        },
        "asset_deleted": {
          "success": "{{- assetTypeName}} \"{{- assetName}}\" ha sido eliminado con éxito.",
          "error": "No se pudo borrar \"{{- assetName}}\"."
        },
        "go_to": "Ir a \"{{- assetName}}\"",
        "record_updated": {
          "success": "¡Ha actualizado correctamente un registro!"
        },
        "questionnaire_sent": {
          "success": "Se ha enviado el cuestionario."
        }
      },
      "side_navigation": {
        "objects_heading": {
          "asset_inventory": "Activos",
          "default": "Objetos"
        },
        "records_heading": "Registros"
      },
      "error_page": {
        "page_title": {
          "asset_inventory": "{{heading}} - Inventario de activos",
          "risk_register": "{{heading}} - Registro de riesgos",
          "default": "{{heading}} - Inventario de objetos"
        },
        "page_not_found": {
          "heading": "No se encontró la página",
          "body": "No se pudo encontrar la página que buscaba. Puede volver a la <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">página de inicio de nuestro sistema</a> o <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">comunicarse con nuestro equipo de soporte</a> para obtener más ayuda."
        },
        "access_not_allowed": {
          "heading": "No se permite el acceso",
          "body": "De acuerdo con nuestros registros, usted no tiene permiso para acceder a esta página. Intente comunicarse con un administrador de HighBond en su organización para saber si debería tener acceso. Alternativamente, puede volver a la <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">página de inicio del sistema﻿</a> ﻿o <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">comunicarse con nuestro equipo de soporte</a> para obtener más ayuda."
        },
        "footer": "Si desea obtener más asistencia, <a href='{{visitOurSupportPage}}' rel=\"noopener noreferrer\" target=\"_blank\"> visite nuestra página de soporte</a>, para crear un ticket o conversar en línea con un representante."
      },
      "add_asset_modal": {
        "unsaved_changes_warning": "Ha realizado cambios que se perderán si cierra este formulario.",
        "add_new_item": "Agregar",
        "name_is_required": "El nombre es obligatorio.",
        "item_type": "Tipo de elemento",
        "name": "Nombre"
      },
      "dirty_form": {
        "title": "Este/a {{- objectTypeName}} tiene cambios sin guardar.",
        "missing_name_error": "Este/a {{- objectTypeName}} no se puede guardar mientras \"nombre\" esté en blanco.",
        "description": "Parece que usted estaba haciendo actualizaciones. Si continúa, perderá todos los cambios no guardados.",
        "save_and_continue": "Guardar y continuar",
        "discard_and_continue": "Continuar sin guardar",
        "cancel": "Cancelar"
      },
      "impact_reports_modal": {
        "download_report": {
          "title": "Descargar reporte",
          "can_be_downloaded": "Los reportes se pueden descargar en los siguientes formatos.",
          "ready_to_download": "Su reporte está listo para ser descargado."
        },
        "just_a_moment": {
          "title": "¡Un momento!",
          "description": "Estamos preparando su reporte. Esto puede tardar varios minutos dependiendo del tamaño del reporte. El botón de descarga estará disponible una vez que el reporte esté listo."
        },
        "format_selection": {
          "label": "Formatos del reporte",
          "instructions": "Seleccione un formato para el reporte"
        },
        "docx_format": "Microsoft Word",
        "xlsx_format": "Microsoft Excel",
        "pptx_format": "Microsoft PowerPoint",
        "pdf_format": "PDF",
        "no_reports": {
          "title": "No se encontró ningún reporte.",
          "description": "En este momento, no hay reportes disponibles para descargar."
        }
      },
      "errors": {
        "handlers": {
          "required_fields_missing": "{{attributeNameComponent}} es obligatorio.",
          "required_relationships_missing": {
            "asset_inventory": "{{attributeNameComponent}} debe estar enlazado a al menos un activo o registro del activo.",
            "default": "{{attributeNameComponent}} debe estar enlazado a al menos un objeto."
          },
          "status_not_found": "El estado de destino ({{statusId}}) no está en el flujo de trabajo del estado actual.",
          "questionnaire_not_found": "El cuestionario no se encuentra en los controladores.",
          "failed_to_update_status": "No se pudo actualizar el estado del flujo de trabajo. Por favor, actualice la página para volver a intentarlo. Si el problema persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">comuníquese con nuestro equipo de soporte</a>.",
          "failed_to_send_questionnaire": "No se pudo enviar el cuestionario de evaluación. Por favor, actualice la página para volver a intentarlo. Si el problema persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">comuníquese con nuestro equipo de soporte</a>.",
          "failed_to_publish_protobuf": "No se pudo ejecutar la acción \"notify\" o \"runRobot\". Por favor, actualice la página para volver a intentarlo. Si el problema persiste, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">comuníquese con nuestro equipo de soporte</a>."
        },
        "form": {
          "missing_field_value": "El campo no puede estar en blanco.",
          "missing_relationship_value": {
            "asset_inventory": "El campo debe estar enlazado a al menos un activo o registro del activo.",
            "default": "El campo debe estar enlazado a al menos un objeto."
          },
          "invalid_field_value": "El campo tiene un valor no válido."
        },
        "configuration": {
          "section_missing_attribute_error": "Algunos atributos que no son visibles para usted deben completarse antes de que pueda continuar. Por favor, comuníquese con su administrador para revisar la configuración de este/a {{- customerObjectTypeName}}.\n"
        }
      },
      "metadata": {
        "record_id": "Identificador del registro",
        "position": "Posición",
        "priority": "Prioridad",
        "status": "Estado",
        "publish_date": "Fecha de publicación",
        "publisher": "Editor",
        "assignee": "Asignado",
        "group": "Grupo",
        "updated_at": "Actualizado en",
        "closed_at": "Cerrado en",
        "object_type": "Tipo de objeto",
        "object_id": "Identificador del objeto",
        "object_container_type": "Tipo de contenedor de objeto",
        "object_container_id": "Identificador de contenedor de objeto"
      },
      "protected": {
        "source": "Origen",
        "user_id": "Identificador del usuario",
        "modified_by": "Modificado por",
        "status_id": "Identificador de estado",
        "priority_id": "Identificador de prioridad",
        "group_id": "Identificador de grupo",
        "external_source": "Origen externo",
        "external_type": "Tipo externo",
        "external_id": "Identificador externo",
        "questionnaire_context": "Contexto del cuestionario"
      }
    }
  }
};
export default locales;