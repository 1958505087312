import AppConfig from 'AppConfig';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { AttachmentDownloadUrl } from 'types/Attachments';
import { deserializeAttachmentDownloadUrl } from './deserializers';

export default async function getAttachmentDownloadUrl(id: string): Promise<AttachmentDownloadUrl> {
  const response = await http.get(`${AppConfig.highbondDriveUrl}/objects?id=${id}`);

  return await handleResponse(response, (json) => deserializeAttachmentDownloadUrl(json));
}
