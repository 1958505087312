import AppConfig from 'AppConfig';
import http from 'api/http';
import { handleResponse } from 'api/helpers';

export default async function deleteAttachment(id: string): Promise<void> {
  const response = await http.delete(`${AppConfig.highbondDriveUrl}/objects?id=${id}`);

  return await handleResponse(response, (json) => {
    return;
  });
}
